import ROUTES from 'temp/routes.json'

export const isSearchPage = (route: string) => {
  return `${route}` === ROUTES.SEARCH
}

export const isShopPage = (route: string) => {
  return `${route}` === ROUTES.SHOP
}

export const isRecentlyPlayedPage = (route?: string) => {
  return `${route}` === ROUTES.RECENTLY_PLAYED
}
