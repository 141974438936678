'use client'

import type { ImgHTMLAttributes } from 'react'
import { useState } from 'react'

type Props = {
  className?: string
  fallbackImg?: string
} & ImgHTMLAttributes<HTMLImageElement>

export default function Img(props: Props) {
  const [url, setUrl] = useState('')
  const { fallbackImg, className, src, ...rest } = props

  return (
    <img
      {...rest}
      className={className}
      src={url || src}
      alt={url || src}
      onError={() => {
        setUrl(fallbackImg || `${process.env.IMG_ORIGIN}/placeholder.jpg`)
      }}
    />
  )
}
