import cx from 'classnames'
import RichText from 'components/RichText'
import classes from './styles.module.scss'
import { useTranslation } from 'src/context/translationContext'
import ROUTES from 'temp/routes.json'

type Props = {
  isCompact?: boolean
  onClick?: () => void
}

export default function ReferFriendBlock(props: Props) {
  const t = useTranslation()

  return (
    <div
      className={cx(classes.root, {
        [classes.compact]: props.isCompact,
      })}
      onClick={props.onClick}
    >
      <RichText
        field={t('common.refer_a_friend_banner_title')}
        components={{
          strong: ({ children }) => (
            <>
              <img src={`${process.env.IMG_ORIGIN}/menu/money22.svg?v=3`} className={classes.icon} alt='sc icon' />
              <span className={classes.textGreen}>{children}</span>
            </>
          ),
          hyperlink: ({ children }) => {
            return (
              <a href={ROUTES.REFER_FRIEND} rel='noreferrer'>
                {children}
              </a>
            )
          },
        }}
      />
    </div>
  )
}
