import type { SyntheticEvent } from 'react'
import { useEffect } from 'react'
import cx from 'classnames'
import { BottomNavigationAction, Drawer } from '@patrianna/core-components'
import { restoreScrollPos, saveScrollPos } from '@patrianna/shared-hooks'
import { useAuth } from 'app/context/auth'
import { yieldToMain } from '@patrianna/shared-utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { toggleMenu } from 'store/modules/dialog/actions'
import { getDialogVisibilityByNameSelector, getIsMenuOpenedSelector } from 'store/modules/dialog/selectors'
import LogoLink from 'containers/LogoLink'
import MenuItems from 'containers/Menu/MenuItems'
import { disableScrollForRoot, enableScrollForRoot } from 'utils/disableScroll'
import classes from './styles.module.scss'
import MenuFooter from '../../../MenuFooter'
import OpenDrawerIcon from './components/OpenDrawerIcon'
import { dismissUnreadPromotionsIcon } from 'store/modules/promotions'
import { BRAND_SCRATCHFUL } from 'config/brands'

function More({
  route,
  availableCategoriesLinks,
}: {
  route: string
  availableCategoriesLinks: Record<string, string>[]
}) {
  const dispatch = useAppDispatch()
  const { isLoggedIn } = useAuth()
  const isOpen = useAppSelector(getIsMenuOpenedSelector)
  const isExtraGoldDialogVisible = useAppSelector((state) =>
    getDialogVisibilityByNameSelector(state, 'EXTRA_GOLD_DIALOG')
  )
  const isShopDialogVisible = useAppSelector((state) => getDialogVisibilityByNameSelector(state, 'SHOP_DIALOG'))

  const toggleMenuAction = async (open: boolean) => {
    // Move to main thread to avoid INP
    await yieldToMain()
    dispatch(toggleMenu(open))
    await yieldToMain()

    if (open) {
      setTimeout(() => {
        saveScrollPos(route)
        disableScrollForRoot()
      }, 200)

      // Move not priority action to the next tick
      await yieldToMain()
      dispatch(dismissUnreadPromotionsIcon())
      await yieldToMain()
    } else {
      enableScrollForRoot()
      restoreScrollPos(route)
    }
  }

  const toggleDrawer = (open: boolean) => (event: SyntheticEvent<any, Event> | KeyboardEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    toggleMenuAction(open)
  }

  useEffect(() => {
    if ((isExtraGoldDialogVisible || isShopDialogVisible) && isOpen) {
      toggleMenuAction(false)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [isExtraGoldDialogVisible, isShopDialogVisible, isOpen])

  useEffect(() => {
    if (!isOpen) {
      enableScrollForRoot()
    }
  }, [isOpen])

  return (
    <>
      {!isOpen ? (
        <BottomNavigationAction
          icon={
            <OpenDrawerIcon>
              <img className={classes.closeImage} src={`${process.env.IMG_ORIGIN}/menu/menu.png`} alt='Menu' />
            </OpenDrawerIcon>
          }
          onClick={toggleDrawer(true)}
          className={classes.navigationButton}
        />
      ) : (
        <BottomNavigationAction
          icon={<img className={classes.closeImage} src={`${process.env.IMG_ORIGIN}/menu/close.png`} alt='Close' />}
          onClick={toggleDrawer(false)}
          className={cx(classes.navigationButton, classes.moreMenuClose)}
        />
      )}

      <Drawer
        className={cx(classes.root, { [classes.topShift]: BRAND_SCRATCHFUL })}
        open={isOpen}
        onClose={toggleDrawer(false)}
        modalClassName={cx(classes.drawerModal, { [classes.topShift]: BRAND_SCRATCHFUL })}
        disableEnforceFocus
        disableBackdrop
      >
        <div className={classes.drawer}>
          <div className={cx(classes.topContent, { [classes.hidden]: BRAND_SCRATCHFUL })}>
            <div className={classes.logoLink}>
              <LogoLink className={classes.drawerLogo} handleOnClick={() => toggleMenuAction(false)} />
            </div>
          </div>
          <MenuItems
            toggleMenu={toggleMenuAction}
            isMobile
            isLoggedIn={isLoggedIn}
            availableCategoriesLinks={availableCategoriesLinks}
          />
          <MenuFooter />
        </div>
      </Drawer>
    </>
  )
}

export default More
