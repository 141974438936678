import type { MutableRefObject, ReactNode } from 'react'
import cx from 'classnames'
import { IconButton } from '@patrianna/core-components'
import classes from './styles.module.scss'

type Props = {
  showArrows?: boolean
  className: string
  classNameContainer: string
  wrapperRef: MutableRefObject<HTMLDivElement>
  scrollRef: MutableRefObject<HTMLDivElement>
  prevScroll: () => void
  nextScroll: () => void
  onScroll?: () => void
  children: ReactNode
  'data-test'?: string
  BackDropNode?: ReactNode
}

const BRAND = process.env.BRAND_NAME

function HorizontalScrollWeb(props: Props) {
  const {
    wrapperRef,
    scrollRef,
    onScroll,
    prevScroll,
    nextScroll,
    className,
    classNameContainer,
    showArrows,
    children,
    BackDropNode,
  } = props

  return (
    <div
      className={cx(classes.scrollWrap, className, 'hidePrevlArrow')}
      ref={wrapperRef}
      data-test={props?.['data-test']}
    >
      {BackDropNode}

      {showArrows && (
        <>
          <IconButton
            className={cx(
              classes.buttonArrow,
              classes.prevButtonArrow,
              { [classes.squaredBtn]: BRAND === 'scratchful' },
              'prev-banner-arrow'
            )}
            onClick={prevScroll}
          >
            <img
              className={classes.arrowImg}
              src={`${process.env.IMG_ORIGIN}/menu/arrow_scroll_left.png`}
              alt='arrow left'
            />
          </IconButton>
          <IconButton
            className={cx(
              classes.buttonArrow,
              classes.nextButtonArrow,
              { [classes.squaredBtn]: BRAND === 'scratchful' },
              'next-banner-arrow'
            )}
            onClick={nextScroll}
          >
            <img
              className={classes.arrowImg}
              src={`${process.env.IMG_ORIGIN}/menu/arrow_scroll_right.png`}
              alt='arrow left'
            />
          </IconButton>
        </>
      )}

      <div className={cx(classes.scrollContainer, classNameContainer)} onScroll={onScroll} ref={scrollRef}>
        {children}
      </div>
    </div>
  )
}

export default HorizontalScrollWeb
