import { usePathname } from 'next/navigation'
import { useMediaQuery } from '@patrianna/shared-hooks'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { openShopHandler } from 'store/modules/shop/actions'
import { shouldForceVerifyKYCSelector, showSpecialOffer } from 'store/modules/user/selectors'
import Img from 'components/Img'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import { useRouter } from 'app/context/navigation'
import { yieldToMain } from '@patrianna/shared-utils'
import { useSpecialOffersAmount } from 'hooks/useSpecialOffersAmount'

export default function SpecialOfferButton() {
  const { push } = useRouter()
  const pathname = usePathname()
  const dispatch = useAppDispatch()
  const isShowSpecialOffer = useAppSelector(showSpecialOffer)
  const amount = useSpecialOffersAmount()

  const isMobile = useMediaQuery('xs')
  const isForceVerifyKYC = useAppSelector(shouldForceVerifyKYCSelector)

  const isHome = pathname === ROUTES.HOME

  if (isShowSpecialOffer && isHome && !isForceVerifyKYC) {
    return (
      <div className={classes.root}>
        <span className={classes.amount}>{amount}</span>
        <button
          type='button'
          onClick={async () => {
            // Move to the main thread to avoid blocking click event
            await yieldToMain()
            dispatch(
              openShopHandler({
                isMobile,
                cta: 'gift_icon',
                location: isHome ? 'homepage' : 'other',
                routerPush: push,
              })
            )
            await yieldToMain()
          }}
          className={classes.button}
        >
          <Img
            className={classes.present}
            src={`${process.env.IMG_ORIGIN}/icons/gift_icon_${isMobile ? 'mobile' : 'desktop'}.png`}
          />
        </button>
      </div>
    )
  }

  return null
}
