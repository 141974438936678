import type { MouseEvent, ReactNode } from 'react'
import cx from 'classnames'
import type { Currencies } from '@patrianna/shared-patrianna-types/store/CurrenciesModule'
import classes from './styles.module.scss'
import { Link } from 'components/Link'

type Props = {
  href: string
  children?: ReactNode
  color?: Currencies
  className?: string
  variant?: 'outlined' | 'contained'
  size?: 'small' | 'large'
  onClick?: (event: MouseEvent) => void
  rel?: string
}

function ButtonLink(props: Props) {
  const { color, children, className, href, rel, size, variant = 'contained', ...rest } = props

  return (
    <Link
      rel={rel}
      href={href}
      role='link'
      className={cx(
        'mt-button-base-root',
        `mt-button-${variant}`,
        size && `mt-button-${variant}-size-${size}`,
        classes.root,
        className,
        color && classes[color]
      )}
      {...rest}
    >
      {children}
    </Link>
  )
}

export default ButtonLink
