import { useAppDispatch } from 'store/hooks'
import { togglePWAMessage } from 'store/modules/appConfig/actions'
import usePWAInstance, { useIsPWAAvailable } from 'hooks/usePWA'
import { useShowPWAModal } from '@patrianna/shared-hooks'
import MenuLink from 'containers/Menu/MenuLink'

import useGetGAEventMenuParams from 'containers/Menu/utilityHook'
import { trackEvent } from 'config/analytic'

type PWAInfoProps = {
  toggleMenu: (isOpen: boolean) => void
}
function PWAInfo({ toggleMenu }: PWAInfoProps) {
  const dispatch = useAppDispatch()
  const { GAEventMenuParams } = useGetGAEventMenuParams()
  const pwaInstance = usePWAInstance()
  const isPwaAvailable = useIsPWAAvailable()
  const shouldShowPWA = useShowPWAModal()
  const isPWAInit = Boolean(isPwaAvailable && pwaInstance)

  const installAppHendler = () => {
    trackEvent(
      'clicked_on_installapp_menu',
      {
        category: GAEventMenuParams.category,
        label: GAEventMenuParams.label,
      },
      { bloomreach: true }
    )
    toggleMenu?.(false)
    dispatch(togglePWAMessage({ isPWAMessageOpen: true }))
  }

  return isPWAInit && shouldShowPWA() && <MenuLink title='install_app' onClick={installAppHendler} icon='install_app' />
}

export default PWAInfo
