export const disableScrollForRoot = () => {
  const rootDiv = document.body

  if (!rootDiv?.classList.contains('disable-scroll')) {
    rootDiv?.classList.add('disable-scroll')
  }
}

export const enableScrollForRoot = () => {
  const rootDiv = document.body

  if (rootDiv?.classList.contains('disable-scroll')) {
    rootDiv.classList.remove('disable-scroll')
  }
}
