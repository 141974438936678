'use client'

import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { usePathname } from 'next/navigation'
import { fetchSavedCards } from '@patrianna-payments/shared-store/payments/store/actions'
import { useAuth } from 'app/context/auth'
import { yieldToMain } from '@patrianna/shared-utils'
import { getPaymentMethodsSelector } from '@patrianna-payments/shared-store/payments/store/selectors'

// [FYI]: Getting all payment methods and getting a flag for A/B testing for Quick Purchase
export default function useGetABTestingFlagForQuickPurchase() {
  const pathname = usePathname()
  const dispatch = useAppDispatch()
  const { isLoggedIn } = useAuth()
  const paymentMethods = useAppSelector(getPaymentMethodsSelector)

  const isPlayPage = pathname?.includes('/play') && !pathname?.includes('/play-')

  useEffect(() => {
    if (isPlayPage && isLoggedIn && !paymentMethods) {
      yieldToMain().then(() => dispatch(fetchSavedCards()))
    }
  }, [isPlayPage, isLoggedIn, paymentMethods])
}
