'use client'

import { Link } from 'components/Link'
import cx from 'classnames'
import { IconButton } from '@patrianna/core-components'
import { useTranslation } from 'context/translationContext'
import Img from 'components/Img'
import { BRAND_SCRATCHFUL } from 'config/brands'
import classes from './styles.module.scss'
import { Information } from 'mdi-material-ui'
import { useAuth } from 'app/context/auth'
import { ArrowIcon } from '../IconButton/components/ArrowIcon'

type Props = {
  title: string
  url?: string
  count?: number
  iconImg?: string
  className?: string
  scrollPrev?: () => void
  scrollNext?: () => void
  showArrows?: boolean
  freeCoinsTag?: boolean
  endScroll?: () => void
  isPrevDisabled?: boolean
  isNextDisabled?: boolean
  titleWithTag?: boolean
  largeTitle?: boolean
  promotionUrl?: string
}

export default function GameRowHeader(props: Props) {
  const { isLoggedIn } = useAuth()
  const t = useTranslation()

  return (
    <div className={cx(classes.root, props.className)}>
      <h2
        className={cx(classes.titleWithLink, {
          [classes.titleWithTag]: props.titleWithTag,
          [classes.largeTitle]: props.largeTitle,
        })}
        data-test={`title-${props.title}`}
      >
        <span>{props.title}</span>
        {props.promotionUrl && isLoggedIn && (
          <Link href={props.promotionUrl} aria-label={props.title} className={classes.promotionLink}>
            <Information />
          </Link>
        )}
        {props.freeCoinsTag && isLoggedIn && (
          <span className={classes.tag} onClick={props.endScroll}>
            {t('common.daily_reward')}
            <Img
              src={`${process.env.IMG_ORIGIN}/icons/arrow-right.svg`}
              className={classes.arrowIcon}
              alt='Arrow right icon'
            />
          </span>
        )}
      </h2>

      {props.showArrows && (
        <>
          <IconButton
            className={cx(classes.buttonArrow, { [classes.squaredBtn]: BRAND_SCRATCHFUL })}
            onClick={props.scrollPrev}
            disabled={props.isPrevDisabled}
          >
            <ArrowIcon />
          </IconButton>

          <IconButton
            className={cx(classes.buttonArrow, { [classes.squaredBtn]: BRAND_SCRATCHFUL })}
            onClick={props.scrollNext}
            disabled={props.isNextDisabled}
          >
            <ArrowIcon rotated />
          </IconButton>
        </>
      )}

      {props.url && (
        <Link
          href={props.url}
          className={cx(classes.link, { [classes.squaredBtn]: BRAND_SCRATCHFUL })}
          data-test={`show-all-${props.title}`}
        >
          {t('common.see_all')}
        </Link>
      )}
    </div>
  )
}
