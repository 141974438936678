import { useRouter } from 'app/context/navigation'
import { IconButton } from '@patrianna/core-components'
import classes from './styles.module.scss'

export default function CloseButton() {
  const router = useRouter()

  return (
    <IconButton className={classes.closeBtn} onClick={router.back} data-test='modal-back-button'>
      <img src={`${process.env.IMG_ORIGIN}/login/close_grey.svg`} alt='close icon' width={12} height={12} />
    </IconButton>
  )
}
