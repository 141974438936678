import type { ReactNode } from 'react'
import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'

type Props = {
  visibleFor: 'mobile' | 'desktop'
  children: ReactNode
}

export default function Hidden(props: Props) {
  const isMobile = useIsMobile()

  if (props.visibleFor === 'mobile' && isMobile) {
    return <>{props.children}</>
  }

  if (props.visibleFor === 'desktop' && !isMobile) {
    return <>{props.children}</>
  }

  return null
}
