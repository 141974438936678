import { useRouter } from 'app/context/navigation'
import type { MouseEvent } from 'react'
import type { JSXFunctionSerializer, JSXMapSerializer } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import { hasLinksInField, replaceRichTextDynamicValuesByConfig } from '@patrianna/shared-utils/prismic'
import { getPageHref } from 'utils/getPageHref'
import { usePrismicDynamicValues } from 'app/context/translations'

type RichTextProps = {
  field: string
  components?: JSXMapSerializer | JSXFunctionSerializer
}

function RichText(props: RichTextProps) {
  const router = useRouter()
  const prismicDynamicValuesConfig = usePrismicDynamicValues()
  const { field, components } = props

  const isTranslationString = typeof field === 'string'
  const isLinksInField = !isTranslationString && hasLinksInField(field)

  const fieldWithCorrectLinksPath =
    !isTranslationString && replaceRichTextDynamicValuesByConfig(field, prismicDynamicValuesConfig || {})

  const renderFields = (fieldWithCorrectLinksPath || field) as never

  const handleClick = (e: MouseEvent) => {
    const href = getPageHref(e)

    if (href) {
      e.preventDefault()
      router.push(href)
    }
  }

  if (isTranslationString) return <>{renderFields}</>

  return isLinksInField ? (
    <div onClick={handleClick}>
      <PrismicRichText field={renderFields} components={components} />
    </div>
  ) : (
    <PrismicRichText field={renderFields} components={components} />
  )
}

export default RichText
