import { Link } from 'components/Link'
import classes from './styles.module.scss'
import ProgressBarItem from 'containers/Menu/MenuItems/VIPProgressBar/components/ProgressBarItem'
import ROUTES from 'temp/routes.json'
import { useTranslation } from 'context/translationContext'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { toggleMenu } from 'store/modules/dialog/actions'
import { format, isValid } from 'date-fns'
import { getUserXpLevelSelector } from 'store/modules/user/selectors'
import Skeleton from 'src/containers/Skeleton'

const MONTHLY_LOYALTY_MAX_LEVEL = 5

function VIPProgressBar() {
  const dispatch = useAppDispatch()
  const xpLevel = useAppSelector(getUserXpLevelSelector)
  const {
    level,
    baseMonthlyLevel,
    nextMonthlyLevel,
    baseAnnualLevel,
    nextAnnualLevel,
    monthlyLevelProgress,
    annualLevelProgress,
    levelValidUntil,
  } = xpLevel || {}

  const isPlatinumLevel = level === MONTHLY_LOYALTY_MAX_LEVEL
  const levelValidDate = new Date(levelValidUntil)
  const isValidDate = isValid(levelValidDate)

  const closeMobileMenu = () => {
    dispatch(toggleMenu(false))
  }
  const t = useTranslation()

  return (
    <Skeleton className={classes.skeleton} condition={typeof level === 'number'}>
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <div className={classes.titlePart}>
            <div className={classes.title}> {t('progressbarxp.title')}</div>
            {isValidDate && (
              <div className={classes.endDate}>
                {t('progressbarxp.resets')} {format(levelValidDate, 'LLLL')}
              </div>
            )}
          </div>
          <div className={classes.iconPart}>
            <img
              src={`${process.env.IMG_ORIGIN}/loyalty-program/loyalty-progress-bar/level${level}.svg`}
              alt={t(`level${level}`)}
            />
            <div className={classes.caption}>{t(`progressbarxp.level${level}`)}</div>
          </div>
        </div>
        <div className={classes.accordionBody}>
          <ProgressBarItem
            maxLevel={MONTHLY_LOYALTY_MAX_LEVEL}
            percent={monthlyLevelProgress}
            currentStep={baseMonthlyLevel}
            nextStep={nextMonthlyLevel}
            title={t('progressbarxp.monthly_progress')}
          />
          {!!nextAnnualLevel && (
            <ProgressBarItem
              percent={annualLevelProgress}
              currentStep={isPlatinumLevel ? null : baseAnnualLevel}
              nextStep={nextAnnualLevel}
              title={t('progressbarxp.status_vip')}
            />
          )}
          <Link href={ROUTES.LOYALTY_PROGRAM} className={classes.moreLink} onClick={closeMobileMenu}>
            {t('progressbarxp.more_information')}
          </Link>
        </div>
      </div>
    </Skeleton>
  )
}

export default VIPProgressBar
