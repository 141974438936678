'use client'

import type { ReactNode } from 'react'
import cx from 'classnames'
import { useScrollRestoration } from '@patrianna/shared-hooks'
import GlobalSnackbar from 'containers/GlobalSnackbar'
import MenuDesktop from 'containers/Menu/MenuDesktop'
import MenuMobile from 'containers/Menu/MenuMobile'
import SmoothscrollLoader from 'containers/SmoothscrollLoader'
import Hidden from 'components/Hidden'
import MaintenanceMessage from 'components/MaintenanceMessage'
import classes from './Layout.module.scss'
import Footer from './Footer'
import Header from './Header'
import FavoriteButton from 'containers/FavoriteButton'
import type { GameProduct, GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import useIsBottomTabsBar from 'hooks/useIsBottomTabsBar'
import BottomTabsBar from 'components/BottomTabsBar'
import useGetABTestingFlagForQuickPurchase from 'hooks/useGetABTestingFlagForQuickPurchase'
import { useIsMobile } from '@patrianna/core-components/src/context/mobileDetectionContext'
import { shouldForceVerifyKYCSelector } from 'store/modules/user/selectors'
import { useAppSelector } from 'store/hooks'

type Props = {
  children: ReactNode
  hideMenu?: boolean
  isHeaderWithLogo?: boolean
  isHeaderWithClose?: boolean
  hideFooter?: boolean
  gameProduct?: GameProduct | GameProductShort
  availableCategoriesLinks: Record<string, string>[]
  hasPatternBg?: boolean
  fullWidthContainer?: boolean
}

export default function Layout({
  hideMenu,
  isHeaderWithLogo,
  isHeaderWithClose,
  hideFooter,
  children,
  gameProduct,
  availableCategoriesLinks,
  hasPatternBg,
  fullWidthContainer,
}: Props) {
  const isBottomTabsBar = useIsBottomTabsBar()
  const isMobile = useIsMobile()
  const isForceVerifyKYC = useAppSelector(shouldForceVerifyKYCSelector)

  useScrollRestoration()
  useGetABTestingFlagForQuickPurchase()

  return (
    <>
      <Header
        favoriteButton={<FavoriteButton gameProduct={gameProduct} />}
        isHeaderWithLogo={isHeaderWithLogo}
        fullWidth={hideMenu}
        isHeaderWithClose={isHeaderWithClose}
        availableCategoriesLinks={availableCategoriesLinks}
      />
      <SmoothscrollLoader />

      {!hideMenu && (
        <div className={cx(classes.menuDesktop, { [classes.disabled]: isForceVerifyKYC })}>
          <Hidden visibleFor='desktop'>
            <MenuDesktop availableCategoriesLinks={availableCategoriesLinks} />
          </Hidden>
        </div>
      )}

      {isMobile && <MenuMobile availableCategoriesLinks={availableCategoriesLinks} />}

      <main
        className={cx(
          classes.main,
          { [classes.fullContent]: hideMenu },
          { [classes.hasPatternBg]: hasPatternBg },
          { [classes.fullWidthContainer]: fullWidthContainer }
        )}
      >
        {process.env.MAINTENANCE && <MaintenanceMessage />}
        <div className={`${isHeaderWithLogo ? '' : 'main-container limited'}`}>{children}</div>
      </main>

      {!isMobile && <BottomTabsBar />}

      {!hideFooter && (
        <div className={cx(classes.footer, isBottomTabsBar && classes.extraPadding, hideMenu && classes.hiddenMenu)}>
          <Footer isFooterCentered={hideMenu} className={`${isHeaderWithLogo ? '' : 'main-container limited'}`} />
        </div>
      )}

      <GlobalSnackbar />
    </>
  )
}
