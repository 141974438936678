import cx from 'classnames'
import classes from './ArrowIcon.module.scss'

type Props = {
  rotated?: boolean
  width?: number
  height?: number
}

export const ArrowIcon = ({ rotated = false, width = 48, height = 32 }: Props) => (
  <img
    className={cx(classes.arrowIcon, { [classes.rotated]: rotated })}
    width={width}
    height={height}
    src={`${process.env.IMG_ORIGIN}/menu/arrow_scroll.png`}
    alt='no ads icon'
  />
)
